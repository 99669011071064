import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "2K+ WQHD User Permission System",
  "path": "/Frequently_Asked_Question/WQHD_User_Permissions/",
  "dateChanged": "2024-02-28",
  "author": "Mike Polinowski",
  "excerpt": "I want to embed my cameras live video in a web page. Is it possible to access the live video without having access to the web user interface?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='WQHD User Permission System' dateChanged='2024-02-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to embed my cameras live video in a web page. Is it possible to access the live video without having access to the web user interface?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_User_Permissions/' locationFR='/fr/Frequently_Asked_Question/WQHD_User_Permissions/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "2k-wqhd-user-permission-system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#2k-wqhd-user-permission-system",
        "aria-label": "2k wqhd user permission system permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2K+ WQHD User Permission System`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to embed my cameras live video in a web page. Is it possible to access the live video without having access to the web user interface? `}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Start by adding a new user in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/System/User/"
      }}>{`User Management Menu`}</a>{` and customize the assigned user permissions:`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#option-1-rtsp-stream"
        }}>{`Option 1: RTSP Stream`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#option-2-mjepg-stream-or-snapshots"
        }}>{`Option 2: MJEPG Stream or Snapshots`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#option-3-mjepg-stream-or-snapshots-and-pantilt-control"
        }}>{`Option 3: MJEPG Stream or Snapshots and Pan\\&Tilt Control`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#additional-permissions-user-settings"
        }}>{`Additional Permissions: User Settings`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#additional-permissions-sd-viewer"
        }}>{`Additional Permissions: SD Viewer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#additional-permissions-multimedia-settings"
        }}>{`Additional Permissions: Multimedia Settings`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#additional-permissions-alarm-settings"
        }}>{`Additional Permissions: Alarm Settings`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#additional-permissions-system-settings"
        }}>{`Additional Permissions: System Settings`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "option-1-rtsp-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#option-1-rtsp-stream",
        "aria-label": "option 1 rtsp stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Option 1: RTSP Stream`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0edbf0612c0328a5ad35e87319121a3c/84bf8/WQHD_User_Permissions_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACh0lEQVQ4y22TXU8TQRSG+SVCsbJtqbYSDPZbuqW0Reru7Mzs1gJGqIqIUSQRFBT50mhEjSZeemOiiReov8Kf9ZidWlTCxZMzN+eZ9+TM9CWTSRKJBMPDw1hDUUZyFdq7P7ny5Afi6Q+am99pPDpk6nGX+qNDvK2ftLYPyY7XsMslyhWbM5aFFY/TF8p6WENDpEdGke0OavYmcuYGXnvB1B5qpoOcW0TP3+f9wQvevdnj8dZDVjZWac3PHBNaFulUCi2FQThNw0SlTDGfJZ/LUMhlyGYz1OoNPr3Z5fPHl7x9vcWz/XVuLF//K4zH411hOo1SmqDVwpMSVwjsSoVcPk8mmyWbyzF28SLV6gRf3u/x7eM+H16t83z3AUtLsycLpZQG13VxXUG1WqVYLB6Rzxdo1GpsrnQ42FnhYH+V7c1lbs3rk4Xa9/F9HyEEruMYoW3blEolCoUCuXyBZqOGFHXudHzebXRYX5olUE36QlG44X+FQRAYwoRSaXw/QCmJVD5B0EJ6nkm+M1fl691xfq2V2NPjTI9nu8Le0+kJw3StVrdR6cCcw7RKa3NWSuG4Lgu6zqK2uTZdwKkWyYym/ib8V6i1NtKw0ROiO/qfGuJ5nqljtSmS5UniuUsMXRjj9Ln0ycIg8JlpX0VKzywnvEAqZfC1RoUjOw7xRIKBwUGi0SiRwUHD/8JYzAivCEXTa+EI2R1bKZTnIppTNJ0mDSG4HApjMfpPnSIyMHCEEfY2HYvFSKXPs3zNZfu2Q6ft4nrKSPXcIurmGtf9OdYcl47jmq/W399PJBI54pjQInk2xb2rFd4u2czLCSrVOvXaJFPBIrXOFrPTAU8vlVmwJ4haFgPHhL8BezXhEkhoWAUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0edbf0612c0328a5ad35e87319121a3c/e4706/WQHD_User_Permissions_01.avif 230w", "/en/static/0edbf0612c0328a5ad35e87319121a3c/d1af7/WQHD_User_Permissions_01.avif 460w", "/en/static/0edbf0612c0328a5ad35e87319121a3c/7f308/WQHD_User_Permissions_01.avif 920w", "/en/static/0edbf0612c0328a5ad35e87319121a3c/c0f7c/WQHD_User_Permissions_01.avif 1162w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0edbf0612c0328a5ad35e87319121a3c/a0b58/WQHD_User_Permissions_01.webp 230w", "/en/static/0edbf0612c0328a5ad35e87319121a3c/bc10c/WQHD_User_Permissions_01.webp 460w", "/en/static/0edbf0612c0328a5ad35e87319121a3c/966d8/WQHD_User_Permissions_01.webp 920w", "/en/static/0edbf0612c0328a5ad35e87319121a3c/4f5bc/WQHD_User_Permissions_01.webp 1162w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0edbf0612c0328a5ad35e87319121a3c/81c8e/WQHD_User_Permissions_01.png 230w", "/en/static/0edbf0612c0328a5ad35e87319121a3c/08a84/WQHD_User_Permissions_01.png 460w", "/en/static/0edbf0612c0328a5ad35e87319121a3c/c0255/WQHD_User_Permissions_01.png 920w", "/en/static/0edbf0612c0328a5ad35e87319121a3c/84bf8/WQHD_User_Permissions_01.png 1162w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0edbf0612c0328a5ad35e87319121a3c/c0255/WQHD_User_Permissions_01.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Giving access to the `}<strong parentName="p">{`Basic Authentication`}</strong>{` and the `}<strong parentName="p">{`RTSP Stream`}</strong>{` allows the user to `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8415_WQHD/Video_Streaming/"
      }}>{`access the RTSP video stream`}</a>{`. But using the login on the webUI does not give access to any of the camera configuration menus:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/219350f389590a31a69dcd183d0f492f/105d8/WQHD_User_Permissions_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC00lEQVQ4y4WT+0uTURyH3/+lC0lmphHmulhhjtQ5nbUkf9D6oZu0qKlBaqZJqKlJpJm2CmIVIyoyootENjUNvMzmZUamm3O1tYsDibw88Z5oWMw68HBezvme5/285z1H8vn9+P1+AoGAYHZ2lrm5ORYXF5mfnxf9ck2e6+vrQ6fTodFoaGpqQvrq8eL1/ZLOzMzg9XpxOBxMT0/jdDpxOqdxuVxhkWsGBwcxmUy0tLTQ0dGBZHd58Hh9QhgMBkVRb28v/f39IeQU4ZDnZKHNZmN0dFSslZxfPLi/efH5fCKhPDgwMCAKZSyWwdDzclgsFvGCiYkJpK/eAIGZoEgn75/b7RZFQ0NDWK3WJXwILQ4nlUNMTk4i2T5+Ynz8s7DLeyfHN5vNdHZ20tXVJXqZ7u7ukEzu/xaHhInJaShVGag0+8nQHiBDm02KJgtVZhbJ6r1s26VEkZBImkaL2dwh0v5TWFJWQGNjFcYb9bQam3lraqTzZgnP71zBdKuByzXlFJed4WShjra2NrEV4T47JGxuOE9tRT49Twy43rXi67rHj1cVuM1GPrY/5G2rgfvGOurqS4XQ+sEqFi/lj59Srs/lYE4GNWU6Hl2/hOfNHYIvKnhtKOOBoYqnxjoMjaVUXdDT3t7O2NgYw8PDjIyM/IGcXD63UlGukoqTGq4V5XK7Uk/P3Uq+PD7HteIcjhzPxdRQzNXSoxQV5GG328UNWVhYELdkKb/HpCf5O7HVqRmu1fLyrJpmfSbfjYd5f1HLs+J03PWpPD4cz3FtCs4pR+jKLdekfckKqvNSuXgqi/y8bApPZHOjNIfq0wcoPKZFfyiNbFUCqpQkphyOZRP+RopSbCc6YTcxiXtQqNLZoU5nS6qazanpxChVRO5QsmKjgi1JKeKcLhWGTRi3eRuxcVuJitnE+tg4Vq/dwMqIdayKiBKsiYgmMj6a7eqdTNmd/xX+BGE0weS2/etBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/219350f389590a31a69dcd183d0f492f/e4706/WQHD_User_Permissions_02.avif 230w", "/en/static/219350f389590a31a69dcd183d0f492f/d1af7/WQHD_User_Permissions_02.avif 460w", "/en/static/219350f389590a31a69dcd183d0f492f/7f308/WQHD_User_Permissions_02.avif 920w", "/en/static/219350f389590a31a69dcd183d0f492f/ad008/WQHD_User_Permissions_02.avif 1170w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/219350f389590a31a69dcd183d0f492f/a0b58/WQHD_User_Permissions_02.webp 230w", "/en/static/219350f389590a31a69dcd183d0f492f/bc10c/WQHD_User_Permissions_02.webp 460w", "/en/static/219350f389590a31a69dcd183d0f492f/966d8/WQHD_User_Permissions_02.webp 920w", "/en/static/219350f389590a31a69dcd183d0f492f/446b5/WQHD_User_Permissions_02.webp 1170w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/219350f389590a31a69dcd183d0f492f/81c8e/WQHD_User_Permissions_02.png 230w", "/en/static/219350f389590a31a69dcd183d0f492f/08a84/WQHD_User_Permissions_02.png 460w", "/en/static/219350f389590a31a69dcd183d0f492f/c0255/WQHD_User_Permissions_02.png 920w", "/en/static/219350f389590a31a69dcd183d0f492f/105d8/WQHD_User_Permissions_02.png 1170w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/219350f389590a31a69dcd183d0f492f/c0255/WQHD_User_Permissions_02.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "option-2-mjepg-stream-or-snapshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#option-2-mjepg-stream-or-snapshots",
        "aria-label": "option 2 mjepg stream or snapshots permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Option 2: MJEPG Stream or Snapshots`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a5400742b2b4999ab8b8f14358f8ad00/077b7/WQHD_User_Permissions_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACl0lEQVQ4y22TWU8TURiG+RtcKK0QOp22VohCqV0EOp22WNoza4e2wYVNAQUUKGVRE4wEMHphohfeqIkL/gh/2mPmVFmiF29m5mTynGfeb05XLBYjEonIhJUQVweHMdpfKW7+ZKJ1QmHjhNzTH2h/knvyXa6L7RPU61kGB+IMDSfo7u4mEAjQpaoqfxMKhYjFB3DmWtQe7uIubGPPtbHn/WzjzG/Le2dhh9ryPlvtLVqbj5h5cB/vbp2SKF8EKopCNBLBqExgizKiXJQpaqOMZZKMphOMpkfIpkbQCwXeHz7j84cjjo+fsfeyxfzK7BkwHA5Lw2g0imGauG4N07QQhomuF8hkb5HOZMhksqRSaTQtx8fDPb692+ftwQYvnq+wuDT9f6BlWXieh2maCGGgaRrJ5AjDiQSJRIKhoSFy4+Pstx7y4WCD1/urvNhZZHFu6iJQUTpAH+Q4DkIIGV3XGRsbI5vNkk6nuZlKc7uoMylKLMzVedVeYH3pDg3PoMsHyQlLoNIxtG1c15UwH96JgWFasgZDCKpC0G7qfFrO8WtjnF0nh9BvdYA+5HQoPtCpMVWvYxiGBDcaDVmD7bhy3XFsCXxU09lpZNk0R6jrKTI34meG54G2bVOv13EdR9r5MB/uW55/Tk5MEtNKRDLjKIkkfQODHeCF38Y3NE28Wu30c/0NfIhpWbJbSw5LEItfI9jXR19/v7xe6e39v6Hwu3KmZGeW5RvZWL6dqFAVVcqGQUUIIqrK5UuXCAYC8pTIk3LeMKwoRKJRmm6Vx3dNpr3OIGzbwpmaxmzO49kes5UqzaqQ7/b09BAMBk9zwdAHhtQoa408X9bzrDUL5EuTlCeKVJrLTD5+wz17hqNcnlW9hKKq/wB/A/7/39wFWj8jAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5400742b2b4999ab8b8f14358f8ad00/e4706/WQHD_User_Permissions_03.avif 230w", "/en/static/a5400742b2b4999ab8b8f14358f8ad00/d1af7/WQHD_User_Permissions_03.avif 460w", "/en/static/a5400742b2b4999ab8b8f14358f8ad00/7f308/WQHD_User_Permissions_03.avif 920w", "/en/static/a5400742b2b4999ab8b8f14358f8ad00/caf2c/WQHD_User_Permissions_03.avif 1166w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a5400742b2b4999ab8b8f14358f8ad00/a0b58/WQHD_User_Permissions_03.webp 230w", "/en/static/a5400742b2b4999ab8b8f14358f8ad00/bc10c/WQHD_User_Permissions_03.webp 460w", "/en/static/a5400742b2b4999ab8b8f14358f8ad00/966d8/WQHD_User_Permissions_03.webp 920w", "/en/static/a5400742b2b4999ab8b8f14358f8ad00/308c4/WQHD_User_Permissions_03.webp 1166w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5400742b2b4999ab8b8f14358f8ad00/81c8e/WQHD_User_Permissions_03.png 230w", "/en/static/a5400742b2b4999ab8b8f14358f8ad00/08a84/WQHD_User_Permissions_03.png 460w", "/en/static/a5400742b2b4999ab8b8f14358f8ad00/c0255/WQHD_User_Permissions_03.png 920w", "/en/static/a5400742b2b4999ab8b8f14358f8ad00/077b7/WQHD_User_Permissions_03.png 1166w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a5400742b2b4999ab8b8f14358f8ad00/c0255/WQHD_User_Permissions_03.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Giving access to the `}<strong parentName="p">{`Basic Authentication`}</strong>{` and the `}<strong parentName="p">{`Live Video`}</strong>{` allows the user to `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/#livestream"
      }}>{`access the JPG snapshot path and MJPEG video stream`}</a>{`. But using the login on the webUI does not give access to any of the camera configuration menus:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ad65b80475af1f617d81d888604e9cfc/0d40b/WQHD_User_Permissions_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADqklEQVQ4y4WSfUzUdRzHf3TH0wmMg8IIjicftpwRD8UzFGLqmIYN5GFaCbUwdgkGTu/k4R44OPB4CEUeREShDIVGC0HHCYkHf7Rp/zT/Kss2Az2PQ9jY5OnV7liulqvP9tpn+26f1z6f977C1NQUZrPZjsViYX5+noWFBZaWluysrCzzX3Xv3i8olQrS9qbR09ON8NBs4dHjGcyWGWZnZ5mbm6N34BvOfX2Rji+7OF5ahkqtolKrQa1WodFoKD2h5LjiGMVHS5AXFbH/YC57MzI5JD+MYLZYmTJbsFpn7cInc3MYmmrQ1CjQnVSTnLKLpOQUouMSiIqJI+ntbcTGJRAR+SbRMbGk7k5jZ+oe4pOSeSM6HmHaPMPDxzNYrdY14ZM5mk/X0linpO1MFaXVKhraG+nsbuXb/m5GBy8zMdzH+GAvNwYv0993gTMdTWjrdciPFiH8/scjHkyv5WfDJm1q1FGnO0JteQEqTQmd5+sZ6u/g9vVefp64iuVmB9NDddwd62dy6BIDfWdpO1/PicpjCIuLi/bwbX3x6VNWWKW1tpTD+1PRKXLRa4tp0B1BX17I3WsXuf99H7Njzcxeq+bXsV5+HLrAUO8XdJ2tRK35HMFgMFChUqPVVlJVVU21voYD+9KIiggjJ30HJR/vo6Qgi0P52RhUnzHS08LCrXYeDFTQri+mu62KK10naWlQUq6QI3h7v4ggCIhE4jXEYta5uSNx82BDkD9VeTtpLk7jnDKLYUMBVwzF/HSpgt++KqFMnkW1Mp+RU4XUFb+P/NM8BKnUE5/16/H1D0AWGIKfLBBfPxkOIjE+Hi605r/FWNkO7p9K5wddBp2Fe/hOe4Dl7jxuVKRzR/8e0/rtnM6JJSc12bahFxFRcUQnphCbuI3I6HgiouJx9/BEIpGQuD2Zg5m7UBRk8lFeJh/kZiH/JBOlPJvcD9PJyH6X1N3vkJAUy9bwUARPqZSt4VGERcUTsnkLvv5ByII2IVnnbo9C4ilFIvXG0UNKfUsbk7fvYLxlwjgxyYhpkuvjJobHbnLVOMrwiHFNGLx5C6+GRtqlYdEJhMck8ZKPj134d0zj4/xfCW5ubgiCAy+IxDg5u+Ds4oqLqysODg7/EhqNRvuQ7YutrKyw/BfLy88QvLy8EItEuLo4IRaLEInWcHR0xNHJCSc7znbh6OioXWgbXF1dfS5C6Guvs3HjJkI2hBAUHExAYBD+sgD8/GXPsL297PsKJpPpH8Lnnfwn84X5i8M7E/oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad65b80475af1f617d81d888604e9cfc/e4706/WQHD_User_Permissions_04.avif 230w", "/en/static/ad65b80475af1f617d81d888604e9cfc/d1af7/WQHD_User_Permissions_04.avif 460w", "/en/static/ad65b80475af1f617d81d888604e9cfc/7f308/WQHD_User_Permissions_04.avif 920w", "/en/static/ad65b80475af1f617d81d888604e9cfc/ed37e/WQHD_User_Permissions_04.avif 1175w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ad65b80475af1f617d81d888604e9cfc/a0b58/WQHD_User_Permissions_04.webp 230w", "/en/static/ad65b80475af1f617d81d888604e9cfc/bc10c/WQHD_User_Permissions_04.webp 460w", "/en/static/ad65b80475af1f617d81d888604e9cfc/966d8/WQHD_User_Permissions_04.webp 920w", "/en/static/ad65b80475af1f617d81d888604e9cfc/92489/WQHD_User_Permissions_04.webp 1175w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad65b80475af1f617d81d888604e9cfc/81c8e/WQHD_User_Permissions_04.png 230w", "/en/static/ad65b80475af1f617d81d888604e9cfc/08a84/WQHD_User_Permissions_04.png 460w", "/en/static/ad65b80475af1f617d81d888604e9cfc/c0255/WQHD_User_Permissions_04.png 920w", "/en/static/ad65b80475af1f617d81d888604e9cfc/0d40b/WQHD_User_Permissions_04.png 1175w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ad65b80475af1f617d81d888604e9cfc/c0255/WQHD_User_Permissions_04.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "option-3-mjepg-stream-or-snapshots-and-pantilt-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#option-3-mjepg-stream-or-snapshots-and-pantilt-control",
        "aria-label": "option 3 mjepg stream or snapshots and pantilt control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Option 3: MJEPG Stream or Snapshots and Pan&Tilt Control`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4f565dbd1ba176e8f16b69af59630b5e/bb3b7/WQHD_User_Permissions_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC8klEQVQ4y23TS28bVRjG8XwFBGXnYIfGiW1AKEpie3xLZhzX8czYc/Vc7MQFpTimohRqEBS7rYJbCQQkSFAEFMSlF7OKSlcICRZQJFasWaDu+jX+aMY0pITFo3f303POe85UKpUimUySTKWIx2dZqngI7nmWzT5p6zWWzD6LD2L0WTLOUWwNSAkyjz36COl0hvn5eR4/doxIJMLUAZhMMnv8SeTnznPyym28C7fwL45pDm7RHI5xhuNwuhfGbF6+zYuDPfpnu3R7p3DaDusNhWUhy9QDLJFIEI/PsVrI0tRqGEoFXS5Tr4qsS3mq4iS1cp5iaZUPRm/x5+8/8sOdG9z87hqffPMxnW5n0jDAJmAcUSrjei0M08K0bBS1zsqqSKFYIl8sUSytsJgW2Nt5g3u/7PPr91+zP77Kl1+9z1a3NWl4GJQkiXa7jW3bmKaJLCsUCgUymQzZbBZByLKwuMy7g1f466eb/Lb/KXduvMf1Ly7T3XKOgqIo4nkeuq7/A8qUSiXy+Tw5QSCXE0hn8wxPtxkNenz+0Q7ffnaFq7sDTvra/zUU8X0/xAJU0zTq9XoYRVFQVYW1qszoTItLZzyu7Wxz98NT7J31aSiVf7d8AJYrbGx2sCwzRAPcdd3wPr3WBp7rUGsYvNMz+HnY4I9RjfujFXabGWqZZ44uRZLKtDc2cBwHy7LwvAlo2U0c1w+vQ9MNtjou271NWh0HVVep1NZYWFz477N5eCkBaBhG2DSYk5iYhk7VtMjoNkuKxrPlE8xlcsw89fTRIwdLafk+tj3BQjjcuIVtN7FNi6Ztk8vlmJ5+gtjMDLFYjGg0GuagYZAAXBUlTLeNZrlhswAMcEuvozdUZF2n0bQRAjASCbFYNDqZsdihr5dIMBufY31thYs9ndefr2OZwfEMTKeFtdXH3jzNy2qDc7pJUcgRmZ4+gMKWAXj42RwPQCnP7ks13n6hSqOuosgyqumhbV/C6LzKmydqDOU6hXT6CBg0/Bsa0hCAsDOC9gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4f565dbd1ba176e8f16b69af59630b5e/e4706/WQHD_User_Permissions_15.avif 230w", "/en/static/4f565dbd1ba176e8f16b69af59630b5e/d1af7/WQHD_User_Permissions_15.avif 460w", "/en/static/4f565dbd1ba176e8f16b69af59630b5e/7f308/WQHD_User_Permissions_15.avif 920w", "/en/static/4f565dbd1ba176e8f16b69af59630b5e/b1723/WQHD_User_Permissions_15.avif 1211w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4f565dbd1ba176e8f16b69af59630b5e/a0b58/WQHD_User_Permissions_15.webp 230w", "/en/static/4f565dbd1ba176e8f16b69af59630b5e/bc10c/WQHD_User_Permissions_15.webp 460w", "/en/static/4f565dbd1ba176e8f16b69af59630b5e/966d8/WQHD_User_Permissions_15.webp 920w", "/en/static/4f565dbd1ba176e8f16b69af59630b5e/52f78/WQHD_User_Permissions_15.webp 1211w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4f565dbd1ba176e8f16b69af59630b5e/81c8e/WQHD_User_Permissions_15.png 230w", "/en/static/4f565dbd1ba176e8f16b69af59630b5e/08a84/WQHD_User_Permissions_15.png 460w", "/en/static/4f565dbd1ba176e8f16b69af59630b5e/c0255/WQHD_User_Permissions_15.png 920w", "/en/static/4f565dbd1ba176e8f16b69af59630b5e/bb3b7/WQHD_User_Permissions_15.png 1211w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4f565dbd1ba176e8f16b69af59630b5e/c0255/WQHD_User_Permissions_15.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Giving access to the `}<strong parentName="p">{`Basic Authentication`}</strong>{`, `}<strong parentName="p">{`PTZ Control`}</strong>{` and the `}<strong parentName="p">{`Live Video`}</strong>{` allows the user to `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/#livestream"
      }}>{`access the JPG snapshot path and MJPEG video stream`}</a>{` and take control of the pan, tilt and zoom control. But using the login on the webUI does not give access to any of the camera configuration menus:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/29a567588a313808a915492930f532ad/f8836/WQHD_User_Permissions_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAClklEQVQoz33S20/SARjGcf6W7lpXrdVFp9XWhc3E5q+TlVR0wvCYjGaGaCgVKMgsNEFFCEVEY0lmzMNs85DpGGaZ1ap1UUvkoMtAXH0bzlVXPdu7d3suPtu7vaJQKEQ4HF6f5eVlEvEEv37+IpWfG/t/mZt7g1KpJCsrC7PZjOhbKExoMUwkEuHHygrPxkZxdLXjHxlgZHSI6ekx5mZe8O5VgPnZGd7MBnk7G1jvAoFxfE+86E16Cq8V0/qgDdHXUIRwNEo0GiUeT9DhdqAsk3P3vg63q4En3S2MP24nONjDhwEb73tuE/S1MNHvpr+7BVdHI1Z7PRqDGrs7BS6E17FoNEI8HsfT5aRKU0yb9Q4NRjUTvTbmB918HvMRe2blR5+KL4NW5ke8TPY56O9pwukwoTeU0elpQ7SwGCEWi22ACTxuO+pSGeY7xeSczKTPbmC2t5lPQx7CwxZWnmr42NfAa38Hk48s9HeacLVqMeoUuDutiJaWljbAKPHEKl5XC+fE+6lUnCH7pBi96jLKIilT3jZiw418f3oT/70StBX5OO9X4bXrsZor0ZbLcdrMiFLYH3A1iae1nrzDO9Epz1KdL+bBzYs8NJTQbrrBjLuaz12l9NbmIpEcQasqwFVfSqNahkIuocls/BeMkEiBllpshQcZ0ghMmaQ8v3WU6WoBW1EGXeoc6M7jZZ2E7rJjTN4+ypolk+HCfVxI342xuvzfkyOsJtew1N0i+8BWdHKBAXUGvgqBe/JDqC9noi8UCNadwn9dTHNBOs6SNBxX9lFzei8ZOzaju6H4C6b+cC2ZpEpfw6Zt20kTBIQTAsJZCcdzZVwoykVWcBFpvozTeVdIP3+JAzlSdh3JZpf4MFv27OWqSs1vvv1WbVFvuaAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/29a567588a313808a915492930f532ad/e4706/WQHD_User_Permissions_16.avif 230w", "/en/static/29a567588a313808a915492930f532ad/d1af7/WQHD_User_Permissions_16.avif 460w", "/en/static/29a567588a313808a915492930f532ad/7f308/WQHD_User_Permissions_16.avif 920w", "/en/static/29a567588a313808a915492930f532ad/028af/WQHD_User_Permissions_16.avif 1214w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/29a567588a313808a915492930f532ad/a0b58/WQHD_User_Permissions_16.webp 230w", "/en/static/29a567588a313808a915492930f532ad/bc10c/WQHD_User_Permissions_16.webp 460w", "/en/static/29a567588a313808a915492930f532ad/966d8/WQHD_User_Permissions_16.webp 920w", "/en/static/29a567588a313808a915492930f532ad/874e8/WQHD_User_Permissions_16.webp 1214w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/29a567588a313808a915492930f532ad/81c8e/WQHD_User_Permissions_16.png 230w", "/en/static/29a567588a313808a915492930f532ad/08a84/WQHD_User_Permissions_16.png 460w", "/en/static/29a567588a313808a915492930f532ad/c0255/WQHD_User_Permissions_16.png 920w", "/en/static/29a567588a313808a915492930f532ad/f8836/WQHD_User_Permissions_16.png 1214w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/29a567588a313808a915492930f532ad/c0255/WQHD_User_Permissions_16.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "additional-permissions-user-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#additional-permissions-user-settings",
        "aria-label": "additional permissions user settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Additional Permissions: User Settings`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1376892f8e235a96dd40ec826c291651/96220/WQHD_User_Permissions_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACl0lEQVQ4y23TW08TQRTAcb4HhragbEsrrUbUtluw2Cu37s7OzrZFuQUoXgBRSRFRsWgM8Zao8cUnfTAmIn4Hv9jf7NBKJDycnJmdzC8z58x2xWIxotEo0WiMcP85LiRHqT4/ZPLxIZWdQya2f1FqHlDaOqC8dUCxeYD95Dfq6U/OXzJJJq+QTKXo7j5DKBSia2BggE4Y/f0kLg4h51ZRC+u4c2s4s3f13J1fa8cq7sIGaqlJ69k2T3YecGutwcKdRSaldQI0DOLxQVzHQjkWojKho5DLMmImyaSvMpy+SjqVpFQe48vbFl8/7/P2zTOevmgyvzJzDEYikTaYQEoXz6viSIktBLl8nszwMKaZwcwM6ysWCwW+vdvl+8cW719t0tq9x9Jy7TQwjuu6KKUQQujI+2Amg2maZEyTVCpNuVRid7PBh5cPeL23we7j2yzOytNBH/M8D9u2sSxLg9ls9h+aSqeZKBepWGXWb02zv7XI5u0ZXGeSLh/yu9wBE4kESnka9UEhFa5SuK5Eup5ecxxHr7Vm8vxYH+XPwxEeOaOMZ9NHoP90Ok3RoFejVqvhCIHyqtTrdY24ytNj6dfWtmmoAqveNVam0oi8SfLi4PEJj7t8dGUf9LPjCI116umPO+BQaZzY9RIR8xrG5Sv0DcZPB6uex/R0Hdne7MN+lu1m+d/92kYGooR6++g7d1bnUG/v/2A4HCaRiGMJl4qsYQupN/tdl46t3+SUVWFcCCYti7BhEOjpIRQM6ggGg0dgp9M+OBhPsHLTZqdhs1AXCEfiSgc1s4xcvM+susF9y2besjHCYQKBgP7lOnHihAbR83E2pnN8Ws2xpArki2OMlYtM1O9QbuwxV6nTGs2xlCtw1jAIngD/AnBb32KZNwuOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1376892f8e235a96dd40ec826c291651/e4706/WQHD_User_Permissions_05.avif 230w", "/en/static/1376892f8e235a96dd40ec826c291651/d1af7/WQHD_User_Permissions_05.avif 460w", "/en/static/1376892f8e235a96dd40ec826c291651/7f308/WQHD_User_Permissions_05.avif 920w", "/en/static/1376892f8e235a96dd40ec826c291651/1d18b/WQHD_User_Permissions_05.avif 1165w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1376892f8e235a96dd40ec826c291651/a0b58/WQHD_User_Permissions_05.webp 230w", "/en/static/1376892f8e235a96dd40ec826c291651/bc10c/WQHD_User_Permissions_05.webp 460w", "/en/static/1376892f8e235a96dd40ec826c291651/966d8/WQHD_User_Permissions_05.webp 920w", "/en/static/1376892f8e235a96dd40ec826c291651/c0c42/WQHD_User_Permissions_05.webp 1165w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1376892f8e235a96dd40ec826c291651/81c8e/WQHD_User_Permissions_05.png 230w", "/en/static/1376892f8e235a96dd40ec826c291651/08a84/WQHD_User_Permissions_05.png 460w", "/en/static/1376892f8e235a96dd40ec826c291651/c0255/WQHD_User_Permissions_05.png 920w", "/en/static/1376892f8e235a96dd40ec826c291651/96220/WQHD_User_Permissions_05.png 1165w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1376892f8e235a96dd40ec826c291651/c0255/WQHD_User_Permissions_05.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adding the `}<strong parentName="p">{`User Management`}</strong>{` permission enables the user to make changes to the user account:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/d74fe/WQHD_User_Permissions_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACFUlEQVQoz22Q3UtTcRzG9x9VdyV6U5Ax8DYC6yYKybC082psvuUmynS1Kcp8WdmLoWV2YUQYdGNEOMEuAukuAimb23Q7O+f8zjmf+J1BEfWFh4ffzef3PE9EVVV0TUPTdXRNRY8Poy5s0TW7Tfdcga7ZAtdmpLZCdUxv0T2/jZ4vMDiWQdN6OBeNcuzEcU41NRFRVB1F09ENEwkfHUuxV7L5WfUp1gK+Vxz2SnV+HIp/9O7tOg8WJ8nm0oxkRlD7DSKabqBqOoZpoigK4+Mp6rUjhFNHnm1VqZQP8IUDgQe+aHgg+LT5mler8zxZmiabG6UvaRIxDINbaiOhBKZSKaq1GpZl4ftB6JVKBcd1cYXAdQWOK91ld3OdjWdTrDxOk5sZZjihNYA9SiOhrCyBEuJ5XpjQsmqUymUcx0EIgRAunvDCd2FjmTdLEyznE+QyMe7032hUVv6qPE6pdMD+/j7FYjFMJ0FB4ANB+Inv+5Qrhyzdz/B0LkEuO8BEUuV2z1Uisq7c0DT/VJYJbdsO3Q1hAa7wsF0P13ERlkXtqMpCepC1ieusDVykv7OdjivtRCTINI3fQJnwf7fz1WZlp87u+89U84vUP3zkyyOD+r3TfOtrwWw7yeVos9zQREoC5Z6xWJzeXo1svJMXaY3VyTgPs0M8zyV5OZdkZSbB8tQQd0diJG9eQL/USm/7Wc63NtN2poVfXMslQqmwCQsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/afe557c0f5ae2bca2816e8bdef539a7d/e4706/WQHD_User_Permissions_06.avif 230w", "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/d1af7/WQHD_User_Permissions_06.avif 460w", "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/7f308/WQHD_User_Permissions_06.avif 920w", "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/db27b/WQHD_User_Permissions_06.avif 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/afe557c0f5ae2bca2816e8bdef539a7d/a0b58/WQHD_User_Permissions_06.webp 230w", "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/bc10c/WQHD_User_Permissions_06.webp 460w", "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/966d8/WQHD_User_Permissions_06.webp 920w", "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/4b3db/WQHD_User_Permissions_06.webp 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/afe557c0f5ae2bca2816e8bdef539a7d/81c8e/WQHD_User_Permissions_06.png 230w", "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/08a84/WQHD_User_Permissions_06.png 460w", "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/c0255/WQHD_User_Permissions_06.png 920w", "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/d74fe/WQHD_User_Permissions_06.png 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/afe557c0f5ae2bca2816e8bdef539a7d/c0255/WQHD_User_Permissions_06.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "additional-permissions-sd-viewer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#additional-permissions-sd-viewer",
        "aria-label": "additional permissions sd viewer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Additional Permissions: SD Viewer`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a36d81d38582e727baabf0214231f88a/cdef6/WQHD_User_Permissions_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACmElEQVQ4y3WSW08TURRG+RX6JAUFWqaFDirai9ACU4otpTNzzlyYEqtiUDGNNmKReAG1auIt6ouaaHwAfPQP+OOWOUetxODDSs5JZtb+9tm7b2xsjGQySTKZIhEfxszM4G59Y2Fjn8qdfcq395hr72qs9i6zt3apdvaxN3c5YZzk1MkJstkcR48cpb+/nz7DMPhDfGSYicks4fo2/voO/vo28to23nV1fvSbHcJWl+jmczqdDVqtNVYuNpCNgNmF0j/CeJx0ehzp1PDcJdylCk6twvnSDFbxHHOFPFYxz0xhiupijS9vu3z98JKXr3Z4+HSL5tULf4Wjo6NaaJomvh8QLkcI6eG6gtm5ObK5HGfOZshkspyenKRUsth794S9949587zDo502l6+EhwullHiekrk4jsP8/DyFQkFTLBaZmp6mWqnwZvsWn15s8rrb5vH9G1xuyv8l9DVKprAsS8vy+Ty5XE6nXDxfRnqLtFsX6W5dp3NzlVDW6FMiNeWDwiAMCcPwl1BIhJA6re0IXCGo1+s4js3mSpnPrTI/OiXuBmUqVuGXMJVK9YaihF6wrIVK4gchUaOBFALpR0SNFf0cqthWw+LZhSmeBBma5TyFU+OHJ/R8XwtV20IIjRII1+3dVbF8rY45X8GYmsXI5hhKm3+FBxMGQUAULfd+VolU20JKfM/XaW3bJmlOMDRqMGIYHB8e4fjQ0OFC6fn4YUOvjZq4KuB7EuHaOK5LXb2jbZOIx+k/doyBWIyBgQFNT6hIJBKk0yYN32E1cgg9R++hak8EEWK5ie96NB2HoG7r72OxGIODgz208M/qqIqpcZPutSrfH1TYuFRjse7iOnW8tXu4nY+0wjU+LFTZqC4xnEjodAeFPwGqguCUhyQYPQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a36d81d38582e727baabf0214231f88a/e4706/WQHD_User_Permissions_07.avif 230w", "/en/static/a36d81d38582e727baabf0214231f88a/d1af7/WQHD_User_Permissions_07.avif 460w", "/en/static/a36d81d38582e727baabf0214231f88a/7f308/WQHD_User_Permissions_07.avif 920w", "/en/static/a36d81d38582e727baabf0214231f88a/63701/WQHD_User_Permissions_07.avif 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a36d81d38582e727baabf0214231f88a/a0b58/WQHD_User_Permissions_07.webp 230w", "/en/static/a36d81d38582e727baabf0214231f88a/bc10c/WQHD_User_Permissions_07.webp 460w", "/en/static/a36d81d38582e727baabf0214231f88a/966d8/WQHD_User_Permissions_07.webp 920w", "/en/static/a36d81d38582e727baabf0214231f88a/5231b/WQHD_User_Permissions_07.webp 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a36d81d38582e727baabf0214231f88a/81c8e/WQHD_User_Permissions_07.png 230w", "/en/static/a36d81d38582e727baabf0214231f88a/08a84/WQHD_User_Permissions_07.png 460w", "/en/static/a36d81d38582e727baabf0214231f88a/c0255/WQHD_User_Permissions_07.png 920w", "/en/static/a36d81d38582e727baabf0214231f88a/cdef6/WQHD_User_Permissions_07.png 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a36d81d38582e727baabf0214231f88a/c0255/WQHD_User_Permissions_07.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adding the `}<strong parentName="p">{`SD Viewer`}</strong>{` permission enables the user to access the alarm recordings stored on your cameras SD card:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/cdef6/WQHD_User_Permissions_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACyklEQVQ4y42RWU8TYRSG54/4GxQTTUy80BvDhRcm3oFgDCFokLZuICmJF8KFLIoCCRpFBVzAogMiiGztUFrURAE1sroREhQ7M6XtdJ/HzJQCjZh4kjdzznz5nu+85whnbRZOV97lxHUPeTUj5FwZJbfGmVK1cyPPqR7lWK1E/lU3eXUSubVjFDaMU3DNRUG9m4M5pezZvQvBYrHg6HMyt5pk+nuAD0shpn+E+LQcYWYlzqflMJ9XYsz+SjD5fY2J2Z9MfpGZXlxlanGVt3O/mFoK8nRIoqC4EKHEYsU9JkE8jLamEA35CQdUSEQwIhENAUkzn/v8jht15XhftBOcaOe31MJ4fxtPOprwvB7BXnURobjEgtPlQtPC+GQZRVXxyYpZ6zqsBQLEYjETODnh4tLJw9ypsrDUU4f7dhnt9WU8aLyES3rJ5cqzCKeKS0xgOBxGlmX8qoqsKGZtRCAQIL4OHB98Tu3xvdy7cISmc0d5WH6UpqID2I9lM/BS5HJFMYLNakWSpE2g34+iKEQiKcuhUIhEImHmM/ML3G5to6OnG7G/l64XvbR0dPLwWQ/O4T5KivIQrH8B1QxgMBgkmUzNMCP0zNLZL5KXvX87YKrDtOWtwHg8bj6kaZp5bvyPRqPmWVfnYw7ty0I4Y7Mx7JR4/zOM9MXH+DcF91cF71KANysRPD/8eJY15pUYejJpQo0lGd/0I0a0dnayY2dWZoeKkrKsKuqGZWMp21nWdd1Uer4DrwbJzc/fBGpaCNnnQ1XVDMtbgWnIVqWBXq+XCrs9BXRJkjnjoJaaj7FZw5YBMjo1Lhl5WrqeXN+KTmK9Y7fbTWlpKYLVdgaPawhWRmChh/8JfwT6Pgbxzq6hiL3EX43gGR2lrPwiwvnzF2hqbEB81IKj7RZdDgeiKP5T3d3dOJ4+4+6DLtoeOXhypwXxfis3m5uxV1TwB2lKud3+29XcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/e4706/WQHD_User_Permissions_08.avif 230w", "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/d1af7/WQHD_User_Permissions_08.avif 460w", "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/7f308/WQHD_User_Permissions_08.avif 920w", "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/63701/WQHD_User_Permissions_08.avif 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/a0b58/WQHD_User_Permissions_08.webp 230w", "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/bc10c/WQHD_User_Permissions_08.webp 460w", "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/966d8/WQHD_User_Permissions_08.webp 920w", "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/5231b/WQHD_User_Permissions_08.webp 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/81c8e/WQHD_User_Permissions_08.png 230w", "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/08a84/WQHD_User_Permissions_08.png 460w", "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/c0255/WQHD_User_Permissions_08.png 920w", "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/cdef6/WQHD_User_Permissions_08.png 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/58a7ee6f79a7c1cc90110cb21a0d7598/c0255/WQHD_User_Permissions_08.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "additional-permissions-multimedia-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#additional-permissions-multimedia-settings",
        "aria-label": "additional permissions multimedia settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Additional Permissions: Multimedia Settings`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/afd0b/WQHD_User_Permissions_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACkklEQVQ4y22Ra08TQRhG+SEqJQZ6RxRoKd0WaWGpdrvbmd2dbZdiRIGIRjSIxKqIRtEYNV4SFTTRGE38pj/xmJ0qePvwZN75MGfOM9OXSqXoJU18aJCR8QLz3T2CzV0d//pbvI1d1Oaejn99j/aN93Ruf+D86hqLiyHCF5hWjamZCn0HwBTxeJxsJoPXtPBFA2mfQjRqWLUqc9UyZqXEXKXEbGUK85TFy4dbfNp7xpOnd9naucmljUv0pdNpDUsmkxo4PDyMrwIdKV2k62HO1SiVyxSNEkapzGTRYHq6wptHt/j6eocXj7vcu7fOlfXlf4HZbBalFO12G9d1kVJimialkkGxWMQwemulUuX59jqfX2zx6uE1HmxfZv3K2YPKfwOjCCE0cGZmRoMKhQITExPk8nnMapV2y+Hq5UV2ttbobqxw4VyLvggUWf5eWSmfQCkNE0LiOI5Ow7ZpNBpYlqX3S36N+8t1vt1scveMRSBOHwD3PyWbJQgXCMNQG3pByPx8R1/iqpCws6BnW0hud2b5uFrmy1KezaaBVRztATOZzIFhNosfhLTDUFu6nsL3faQU+k2jOYobPYVtM3m6zuh0ldFSmdTY2J+GiURiv7L6Wdl1e4nm3t7dn48XigyOnCAxcpyjqTRHk8m/gb3KrVaLhYUOyvfxPI8gCFAqwPtp57muNozOHT50iIFYjFisn1gs1gP++ulfhk3p0RA+TeH2DD0PVwqkY2E7NnUhcKTUl/f39zMwMLCfP4DJRJxE5hgPLjp8325wY6mJI3082UStdFHdd6yeWWO3bnOn6ZKOgEeO/B8YrZFhMpWmYuSonxyjPDnOeC5PPpcjPzlFrmxSnDCYHR3j5HiOwaEhXfN34A8NEtt7tINTmgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/e4706/WQHD_User_Permissions_09.avif 230w", "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/d1af7/WQHD_User_Permissions_09.avif 460w", "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/7f308/WQHD_User_Permissions_09.avif 920w", "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/9d96d/WQHD_User_Permissions_09.avif 1153w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/a0b58/WQHD_User_Permissions_09.webp 230w", "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/bc10c/WQHD_User_Permissions_09.webp 460w", "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/966d8/WQHD_User_Permissions_09.webp 920w", "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/99eb3/WQHD_User_Permissions_09.webp 1153w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/81c8e/WQHD_User_Permissions_09.png 230w", "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/08a84/WQHD_User_Permissions_09.png 460w", "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/c0255/WQHD_User_Permissions_09.png 920w", "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/afd0b/WQHD_User_Permissions_09.png 1153w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ba1e06987d29c1b7e3227f2b41e51d2e/c0255/WQHD_User_Permissions_09.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adding the `}<strong parentName="p">{`Multimedia`}</strong>{` and `}<strong parentName="p">{`Audio Features`}</strong>{` permission enables the user to access the multimedia menu and make changes to your cameras video, image and audio settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/d74fe/WQHD_User_Permissions_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACtElEQVQoz2WS109TARjF77/js5oY44yJidYGGSaIJTIk1AKWUZBqe0ttLaIGbRmKdSAOFBkFFLW1zCpRH6SOxNUEiHHctvdCBCyt8DOXV0/yvZ78zneOIEkSsViMaDTK3NwciUSCVOovqpLJFCsrK6ysrpJM/WWV/xWJRLBaRdL3p2MwGBCkaAwpFkeWZRYXFvj45RO+IR9PR57gH37E5Isgb19P8Hlqkul3r5gJP+frm+d8mpok/HqCx0O9tLS5EZ1W6urtCD+jceKygqIoJBLLjI49o9asp6nZQedtD/4eL5ODt/gw3MPs2F1i/SKRvgbCgS5CAx08fODlzt0Wmr1nafA4VEN5zVAlVOOOjwURRSMdXheWaj2+9kYiI13MTPiQQvdYCpxGeeZmeryX9/5Oxvq99Ha6uXLZidtTh/ArJiPFFOJxeY3wRSjI8VIdbQ2V5OdoaHRW8fjqacL97Uijt0kE65ntryfU1Uqop5VgVxPd7S68TRYunjMj/JDiqLHVXy4t/eHlRIA87Q5KC7MoOKzlRGU+lso8rnvOMBu4wbzfxVS7CVtlLicsZfTdPMeVpjoaXSZcViPCtx/Smlk0KvF7cYmRoT6cuq1cqkij25bBPWsOr1qMDJ4vY7C5mm8PzISvGbEbc6jQ5zLgqWDAkYuj5CDlZUUI36U4sqKgyDJ/llOMD3XTVrCRgHUv0xe0zLRmM2zeS69Jg+OIloVOPYlrOoZtmbxvSGexVYPi3IZt/yaOZe1GUAtR96eWkkyl8HXfZ/P6deRrNuIu2onXlIa9OI3a4kxq9JlcP5lNR3UG1sJ9mAs1lGbvojxrG/u2b0C7awvC/Pw86qmUyeVlHvkD7Mk+hO5oCfnGcgpMNRRbRIyn7NTYRarsNiqcDopFO3lmEV1VLQcMRjIKi8gxlPAPXtlUnLYfUn0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/e4706/WQHD_User_Permissions_10.avif 230w", "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/d1af7/WQHD_User_Permissions_10.avif 460w", "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/7f308/WQHD_User_Permissions_10.avif 920w", "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/db27b/WQHD_User_Permissions_10.avif 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/a0b58/WQHD_User_Permissions_10.webp 230w", "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/bc10c/WQHD_User_Permissions_10.webp 460w", "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/966d8/WQHD_User_Permissions_10.webp 920w", "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/4b3db/WQHD_User_Permissions_10.webp 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/81c8e/WQHD_User_Permissions_10.png 230w", "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/08a84/WQHD_User_Permissions_10.png 460w", "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/c0255/WQHD_User_Permissions_10.png 920w", "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/d74fe/WQHD_User_Permissions_10.png 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8bf730a7b0d7ffba85de4e63ee0474ae/c0255/WQHD_User_Permissions_10.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "additional-permissions-alarm-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#additional-permissions-alarm-settings",
        "aria-label": "additional permissions alarm settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Additional Permissions: Alarm Settings`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a93e654510065ba840636f7487aab8cb/a9965/WQHD_User_Permissions_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACmElEQVQ4y22T204TURSGeQq9UqgB6cy0AlFJi5RaZTo9gJ19mLYzpTQeMGJBCtLgMYiCMUL0Qi9MjBpFjQ/hu31mpqhBufizVvbFt/+1/r37kskklmVFMow4ydFzzK5/wl77hrO2j726T27lC5dCdb5EvXP3K7PdfQbPpBgdSXL27DmOHztOf38/faZp8lvx4WGSI2PU25sEK8/xl7eptp/itZ9RXXpGtd1TfXmHxuou690unc4t5q/PI32NXS78A4zHsSwTMVtAu+WoujMOJfsil7MTXJ5KM51Nk5uaoFgs8W5vi8/v9tjd3eTx9n1uriz8BRqGcQBMIJWmVvdRSiOkwikUmcpeZHIyQyYzxcSFSfK2zce9Tb6/3eb1iw2ePOmwuDR/FNBCa00QBCilEFKSz+dJp9OkUinSqRTj4+NMT0/z8vEd3u/e49X2KluP2ty+GRwNlFLieR5CiEiO45DL5chms2QymcjpTNGhIku0F+fZebBId/kqzUDSF4J6CR926Pt+DygVUimUlAihostc10W4LhtzDh+W8vzcsHlYy+MWcn+Bv0NJhA49n6Axh5ICXfNpzDXxtEJWG9F5terhCsl6kGenlWWrlqZVmCR7fuR/YNgr7RE0GtSq1SggpTXywG24V616Ti+Ur3DGLmFmLmGkJhgaG+sBDz2bRAKlJPV6LaohINxnCA0V9iEwXEdydIzY6WGG4nFODQ1xanDw6JGF0nj1AKk9tO4Bw5GVcHGFS0UpKkJgGgYnT5wgNjDAwIEOOQy/nmklCDyXdkvRrMsoEC905jdRczeo6TrXKi4NV2BaVvTdYrHYHx0GxuOcNhLcbTr8uOfQbRVxyhUqMyXcVofK2hsWagu8sh3WCmWGTfM/4C8FOd+GnUuqVwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a93e654510065ba840636f7487aab8cb/e4706/WQHD_User_Permissions_11.avif 230w", "/en/static/a93e654510065ba840636f7487aab8cb/d1af7/WQHD_User_Permissions_11.avif 460w", "/en/static/a93e654510065ba840636f7487aab8cb/7f308/WQHD_User_Permissions_11.avif 920w", "/en/static/a93e654510065ba840636f7487aab8cb/39686/WQHD_User_Permissions_11.avif 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a93e654510065ba840636f7487aab8cb/a0b58/WQHD_User_Permissions_11.webp 230w", "/en/static/a93e654510065ba840636f7487aab8cb/bc10c/WQHD_User_Permissions_11.webp 460w", "/en/static/a93e654510065ba840636f7487aab8cb/966d8/WQHD_User_Permissions_11.webp 920w", "/en/static/a93e654510065ba840636f7487aab8cb/37778/WQHD_User_Permissions_11.webp 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a93e654510065ba840636f7487aab8cb/81c8e/WQHD_User_Permissions_11.png 230w", "/en/static/a93e654510065ba840636f7487aab8cb/08a84/WQHD_User_Permissions_11.png 460w", "/en/static/a93e654510065ba840636f7487aab8cb/c0255/WQHD_User_Permissions_11.png 920w", "/en/static/a93e654510065ba840636f7487aab8cb/a9965/WQHD_User_Permissions_11.png 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a93e654510065ba840636f7487aab8cb/c0255/WQHD_User_Permissions_11.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adding the `}<strong parentName="p">{`Alarm Settings`}</strong>{` permission enables the user to access the alarm menu and everything recording related:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/511f0/WQHD_User_Permissions_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACwUlEQVQoz6WS2U5TARCG+x4+gJd4o4lAjIkrcZcgAgUjGgVBDBJEBbQoUGxVWhcilooCslgCkggKtRRtpVoKIhrickEISJfTnlNb6ObyGVrfwEn++e++zD8zMpfTicfjwev1xhUIBAiFQsRiP4nFYiyvrMQ9HInE/dcf+A38+r3awelyUa9UkpmZSXFRMTK34MPjFeMSRZHl5WXMFjNt3a30DxoYHunH9uo5MzYTXx2vmJ8Y4dv4ILPvRpl6Y+TFUC/6h00ob9ahaFAgW3K6mVtYwuX2IAgCgUCQ9nYdl6qLaNFdo7ddi3WglZmRbuasT5GG1bj6qvhsfMzE806MfXqedDXR1KKiQaNA5vf7cQoikt+PJEmEQmG6Oh/QUF/GXVU55WeO4XjWyvyYgUVLH36TlqCxgXlzF19MPVgHdAx0adA319LYWIVMFCW+u70IPikeORSOYOi4x9n8gygv5JMjP4BeXYZeXcWCqYeQWYtv8ApDzTUYWpQYuzUYWutp1lairitDJnh9LLkFREmKT7gSjtCj15K9I5nK4gyK8vehu1rADUUpw+0anEMqZh9XcKfyCDnH5HTcq+X+zYuoFWeoLi9MRF7y+JAkP/44MEpvUy0PCzbysvYgjuvp2FS5fNLmo6vIYvp+CT+6S+i7koe29BCTt47yTb0f1dE0TuRlJIDf3YlL+0SRcCTKo8YaKtOTMJRuYf72AV5e3k/b6Z2cz0ujvyaLUEs2wxW7MVfvwa7YzmTFJi7s2cDJtJQE0C2I/yKLRKNRzlVVs2btWnalbSY7YydZuenkHJdTcEpO4cksCgvlHDpymG2ZGaTu3cf6rdtYl5pMUmrK6h96WXQldrgKDwaDfJydZWRsjNHxt5hsdsbsDqyTU7x2TDE+9R77h+m4WxyTWOwTmMdtGF9bGLVYkEn/Int9CeCq/qf+Ap50seYLDvxhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5f540fdd08e42b6be3c4e7c9393235e/e4706/WQHD_User_Permissions_12.avif 230w", "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/d1af7/WQHD_User_Permissions_12.avif 460w", "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/7f308/WQHD_User_Permissions_12.avif 920w", "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/753ad/WQHD_User_Permissions_12.avif 1172w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e5f540fdd08e42b6be3c4e7c9393235e/a0b58/WQHD_User_Permissions_12.webp 230w", "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/bc10c/WQHD_User_Permissions_12.webp 460w", "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/966d8/WQHD_User_Permissions_12.webp 920w", "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/40d03/WQHD_User_Permissions_12.webp 1172w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5f540fdd08e42b6be3c4e7c9393235e/81c8e/WQHD_User_Permissions_12.png 230w", "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/08a84/WQHD_User_Permissions_12.png 460w", "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/c0255/WQHD_User_Permissions_12.png 920w", "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/511f0/WQHD_User_Permissions_12.png 1172w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e5f540fdd08e42b6be3c4e7c9393235e/c0255/WQHD_User_Permissions_12.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "additional-permissions-system-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#additional-permissions-system-settings",
        "aria-label": "additional permissions system settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Additional Permissions: System Settings`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0430a1718da2bdaeb187eed73fe60648/a9965/WQHD_User_Permissions_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAClklEQVQ4y22TXU8TQRhG+RncKK2itLutoEYsyLbYlm6ptt2Z2Y/u0hqIiYBURcQiRhES4icxXpioF15gIvgr/GvH7BTBqBdP5t2bs2fyzDuQzWbJZDKYpkk6nSIzehmnt0+194Na7wB7/ZDS2gHlo5Qefme2d4izeUDqksXFsQtcGR9ncHCQRCLBgGEY/E5qZIRMdhR/cZOwu0Nwdwtv6Tne0hb+8kmCu9uED16x8WSD9UcrLNyZx++0qDZv9oGxnQamUvoUjRqeqCPqszj1KrXKdYr5Ca5bOYpWjumpHHa1yqe323z7vMfe3jZbu09YWr1zYphOpzUwhgupCFotlHL1bFdnyRemsfJ58vkC16YsZmbKfH23xeHHXT683mBne5WVe/P/ByqlCMNQn0JIKpUKk5OT5HI5nfGrVymXSrzc7PLlTY/3uw/ZebZCdzH6P1BKSRAECCF0bNumWCxSKBSwLEsb3py1aYgay4ttXj1d4nF3nk4kGYhB/YZPgK7r0mq1NEwqV19dKXk8y6MfbbZt9u/N8HOjxDO/jLCn+8D42fwuxTQNhNsimmujpMT1Q+baHTxXIf2IMGrj+x6OEKyGNi86BZ66E8zZU+SvjJ4Y/gl0VKCBQeD3rVwXKYUuKDZ09bdk8kaDbLmGYRVJ5SYYvnipDzx+h8elSMKwpUuJ43mehsTxPR9XlyXIjo2RHD7HuZERzgwPc+bs2b8Mj2YnNvEjhLZRuDFQSZRo4DhN6lLSEALTMDh96hTJREJvid6UPw3j1UsbJh2/ydptxULUL8KLzaJ51K1lQi9isdnkliMwMxmGhoZIJpPH+Qd4Pm1yP6qwv15hrVOlUqtTv1Gl0e7SuP+eBe82b8oVVu0aKcP4B/gL46nfe+gmbJIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0430a1718da2bdaeb187eed73fe60648/e4706/WQHD_User_Permissions_13.avif 230w", "/en/static/0430a1718da2bdaeb187eed73fe60648/d1af7/WQHD_User_Permissions_13.avif 460w", "/en/static/0430a1718da2bdaeb187eed73fe60648/7f308/WQHD_User_Permissions_13.avif 920w", "/en/static/0430a1718da2bdaeb187eed73fe60648/39686/WQHD_User_Permissions_13.avif 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0430a1718da2bdaeb187eed73fe60648/a0b58/WQHD_User_Permissions_13.webp 230w", "/en/static/0430a1718da2bdaeb187eed73fe60648/bc10c/WQHD_User_Permissions_13.webp 460w", "/en/static/0430a1718da2bdaeb187eed73fe60648/966d8/WQHD_User_Permissions_13.webp 920w", "/en/static/0430a1718da2bdaeb187eed73fe60648/37778/WQHD_User_Permissions_13.webp 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0430a1718da2bdaeb187eed73fe60648/81c8e/WQHD_User_Permissions_13.png 230w", "/en/static/0430a1718da2bdaeb187eed73fe60648/08a84/WQHD_User_Permissions_13.png 460w", "/en/static/0430a1718da2bdaeb187eed73fe60648/c0255/WQHD_User_Permissions_13.png 920w", "/en/static/0430a1718da2bdaeb187eed73fe60648/a9965/WQHD_User_Permissions_13.png 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0430a1718da2bdaeb187eed73fe60648/c0255/WQHD_User_Permissions_13.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adding the `}<strong parentName="p">{`System Settings`}</strong>{` permission enables the user to access to everything else - network, features and system settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b017934e3096ca9048b8b197e421bc5/a9965/WQHD_User_Permissions_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACrklEQVQoz23SV09TARiH8X4cr7zQC4PrwqgoYAAlKgqGKEjVMoQOCkLBgRiWUiyylEIZVoUSR5UeaolEQkINMtQgjogabc85tBpEux7TauKN/w/wy5M3r8Lr8SBJIqIo4vP5+Lm6ChFiCwZDRCIQDkcIhsL8bzMzsxQUFJCYmERtbS2KLx4voighyzIrKyssLC5gH7XjevoY17iAe2qcF9OTvJmbYunVNEsv3byfd7M452b22QQP7DYuG+vRlevoNF9H8cUr8VWUY2AgEMQh2NHqcmk2XaDf0sSjOx1M3Oth3jnIB5cFz1AFC7YGph1WxobNDFvbsPSZqLt6DvNAJ4rPHgmvFAUlAoEAgmCn/IwKc3s1VXoV9l4ji4+tfBgfxvukl1XHeUTByNuxQWYe9eK0tXO77wpGo4GBmx3RQpllny9W+CsQxOW0U6I6TGddMUcOJWK8qEO4UcPsXTNeVzc/Ry/yznaJyaE2ng62IFibuNVVTXO9ln6L6U+hvLwcK4yCT4T7pO/aTGFuOhkZSVRojmLQZNPb0sAnRwffHdVMdRZTUpjJ+XPFDHXX0dZcRU1lPl2tDShi9/sLBkJhRoatGA7G0VKUQndpClZDBu7WYoZq8xi5puOjVc9Uax4a5QF0eVk8NBVxp/IIJTn7aayvRvH5q4RHlJEkiV+hCE5bHx3H1iNUJLBkTOa18QBjZQn0Fe6mRplM0HqC5dbDCIZ9PK9J5YcpkaWKrZyOX0djacG/wigY/bdb/RY2rV/DydSNtKu2065OoSo3Fb0yDY0yjZ7yQ3Rr9lKZswfD8STUGTvQHNzGzri1aPOVKL75/fj9fiRZJhIOMTBoY0N8Avuyskg7lk26Ko8sjYaTZXpOl6lR6bUcL9WTqdayP7+I5NxTxGceZUtyKurKs/wGU8tWV6B4IKQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b017934e3096ca9048b8b197e421bc5/e4706/WQHD_User_Permissions_14.avif 230w", "/en/static/0b017934e3096ca9048b8b197e421bc5/d1af7/WQHD_User_Permissions_14.avif 460w", "/en/static/0b017934e3096ca9048b8b197e421bc5/7f308/WQHD_User_Permissions_14.avif 920w", "/en/static/0b017934e3096ca9048b8b197e421bc5/39686/WQHD_User_Permissions_14.avif 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b017934e3096ca9048b8b197e421bc5/a0b58/WQHD_User_Permissions_14.webp 230w", "/en/static/0b017934e3096ca9048b8b197e421bc5/bc10c/WQHD_User_Permissions_14.webp 460w", "/en/static/0b017934e3096ca9048b8b197e421bc5/966d8/WQHD_User_Permissions_14.webp 920w", "/en/static/0b017934e3096ca9048b8b197e421bc5/37778/WQHD_User_Permissions_14.webp 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b017934e3096ca9048b8b197e421bc5/81c8e/WQHD_User_Permissions_14.png 230w", "/en/static/0b017934e3096ca9048b8b197e421bc5/08a84/WQHD_User_Permissions_14.png 460w", "/en/static/0b017934e3096ca9048b8b197e421bc5/c0255/WQHD_User_Permissions_14.png 920w", "/en/static/0b017934e3096ca9048b8b197e421bc5/a9965/WQHD_User_Permissions_14.png 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b017934e3096ca9048b8b197e421bc5/c0255/WQHD_User_Permissions_14.png",
              "alt": "2K+ WQHD User Permission System",
              "title": "2K+ WQHD User Permission System",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      